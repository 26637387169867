import React from 'react'
import { Nav, NavLink, NavbarContainer, Span, NavLogo, NavItems, GitHubButton, ButtonContainer, MobileIcon, MobileMenu, MobileNavLogo, MobileLink, Image } from './NavbarStyledComponent'
import { DiCssdeck } from 'react-icons/di';
import { FaBars } from 'react-icons/fa';
import { Bio } from '../../data/constants';
import { Close, CloseRounded, GitHub, LinkedIn, YouTube } from '@mui/icons-material';
import { useTheme } from 'styled-components';

const Navbar = () => {
  const [isOpen, setIsOpen] = React.useState(false);
  const theme = useTheme()
  return (
    <Nav>
      <NavbarContainer>
        <NavLogo to='/'>
          <a style={{ display: "flex", alignItems: "center", color: "white", marginBottom: '20;', cursor: 'pointer' }}>
            <Span> <Image src="./Logo.png" /></Span>

          </a>
        </NavLogo>
        <MobileIcon>
          <FaBars onClick={() => {
            setIsOpen(!isOpen)
          }} />
        </MobileIcon>
        <NavItems>
          <NavLink href="#about">About</NavLink>
          <NavLink href='#skills'>Skills</NavLink>
          <NavLink href='#experience'>Experience</NavLink>
          <NavLink href='#projects'>Projects</NavLink>
          <NavLink href='#education'>Education</NavLink>
        </NavItems>
        <ButtonContainer>
          <GitHubButton href={Bio.github} target="_blank"><GitHub fontSize='large' /></GitHubButton>
          <GitHubButton href={Bio.linkedin} target="_blank"><LinkedIn fontSize='large' /></GitHubButton>
          <GitHubButton href={Bio.youtube} target="_blank"><YouTube fontSize='large' /></GitHubButton>
        </ButtonContainer>
        {
          isOpen &&
          <MobileMenu isOpen={isOpen}>
            <MobileLink href="#about" onClick={() => {
              setIsOpen(!isOpen)
            }}>About</MobileLink>
            <MobileLink href='#skills' onClick={() => {
              setIsOpen(!isOpen)
            }}>Skills</MobileLink>
            <MobileLink href='#experience' onClick={() => {
              setIsOpen(!isOpen)
            }}>Experience</MobileLink>
            <MobileLink href='#projects' onClick={() => {
              setIsOpen(!isOpen)
            }}>Projects</MobileLink>
            <MobileLink href='#education' onClick={() => {
              setIsOpen(!isOpen)
            }}>Education</MobileLink>
            <div style={{ display: "flex", gap: 10 }}>
              <GitHubButton style={{ padding: '10px 10px', background: `${theme.primary}`, color: 'white', width: 'max-content' }} href={Bio.github} target="_blank"><GitHub /></GitHubButton>
              <GitHubButton style={{ padding: '10px 10px', background: `${theme.primary}`, color: 'white', width: 'max-content' }} href={Bio.linkedin} target="_blank"><LinkedIn /></GitHubButton>
              <GitHubButton style={{ padding: '10px 10px', background: `${theme.primary}`, color: 'white', width: 'max-content' }} href={Bio.youtube} target="_blank"><YouTube /></GitHubButton>
            </div>

          </MobileMenu>
        }
      </NavbarContainer>
    </Nav >
  )
}

export default Navbar